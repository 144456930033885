import { Container, Typography, Box } from "@mui/material";

const Contactos = () => {
  return (
    <Container maxWidth="md" sx={{ textAlign: "center", padding: "20px" }}>
     
      {/* Google Maps Embed */}
      <Box
        sx={{
          backgroundColor: "#fff",
          width: "100%",
          maxWidth: "1024px",
          margin: "auto",
          marginBottom: "20px",
        }}
      >
        <iframe
          title="Contactos"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3003.6810151457134!2d-8.647214500000013!3d41.1633113!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd24659b11599221%3A0xdd85b52e5d993037!2sR.+Afonso+Lopes+Vieira+208%2C+4100-020+Porto!5e0!3m2!1spt-PT!2spt!4v1431614848994"
          width="100%"
          height="500"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </Box>

      {/* Informações de Contato */}
      <Box sx={{ textAlign: "left", paddingLeft: "20px" }}>
        <Typography variant="h6">
          <b>Morada:</b> Rua Afonso Lopes Vieira, 208, 4100-020 Porto (Foco)
        </Typography>
        <Typography variant="h6">
          <b>Email:</b> <a href="mailto:geral@chanceleiloes.com">geral@chanceleiloes.com</a>
        </Typography>
        <Typography variant="h6">
          <b>Telefone:</b> 223 294 275 (Chamada para a rede fixa nacional)
        </Typography>
        <Typography variant="h6">
          <b>Avaliações, Entrega e Levantamento de Peças:</b> 2ª a 6ª feira das 14:00 às 20:00
        </Typography>
      </Box>
    </Container>
  );
};

export default Contactos;
