import React, { useState } from "react";
import { AppBar, Toolbar, Button, Menu, MenuItem, IconButton, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import {Link} from "react-router-dom";

const MenuBarra = () => {
  const [submenuEquipaAnchorEl, setSubmenuEquipaAnchorEl] = useState(null);
  const [submenuRegulamentoAnchorEl, setSubmenuRegulamentoAnchorEl] = useState(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(null);

  // Abrir e fechar menus
  const handleSubmenuEquipaOpen = (event) => setSubmenuEquipaAnchorEl(event.currentTarget);
  const handleSubmenuEquipaClose = () => setSubmenuEquipaAnchorEl(null);

  const handleSubmenuRegulamentoOpen = (event) => setSubmenuRegulamentoAnchorEl(event.currentTarget);
  const handleSubmenuRegulamentoClose = () => setSubmenuRegulamentoAnchorEl(null);

  const handleMobileMenuOpen = (event) => setMobileMenuOpen(event.currentTarget);
  const handleMobileMenuClose = () => setMobileMenuOpen(null);
  const menuItemStyle = {
        padding: "2px 12px",
        minHeight: "unset",
        fontSize: "0.875rem", };// 14px
  const menuItemStyleMov = {
          padding: "5px 10px",
          minHeight: "unset",
          fontSize: "0.875rem", };// 14px
          
  
  return (
    <AppBar position="static" sx={{ backgroundColor: "#6f6f6f", maxHeight: "50px"}} >
      <Toolbar>
        {/* Menu para Mobile */}
        <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleMobileMenuOpen} sx={{ display: { xs: "block", md: "none" } }}>
          <MenuIcon />
        </IconButton>

        <Typography variant="h7" sx={{ flexGrow: 1 }} component={Link} to="/" color="white">
          ChanceLeiloes.com
        </Typography>

        {/* Menu Desktop */}
      
         <Button color="inherit" 
                 component={Link} to="https://chanceleiloes.bidspirit.com/#home" 
                 sx={{ display: { xs: "none", md: "block" } }}>
          LEILÕES
        </Button>
        <Button color="inherit" 
                onClick={handleSubmenuEquipaOpen} 
                sx={{ display: { xs: "none", md: "block" } }}>
          A NOSSA EQUIPA &gt;
        </Button>
        <Button color="inherit" 
                onClick={handleSubmenuRegulamentoOpen}
                sx={{ display: { xs: "none", md: "block" } }}>
          REGULAMENTO &gt;
        </Button>
         <Button color="inherit" 
                 component={Link} to="/avaliacoes"
                 sx={{ display: { xs: "none", md: "block" } }}>
          AVALIAÇÕES
        </Button>
        <Button color="inherit" 
                component={Link} to="/faqs"
                sx={{ display: { xs: "none", md: "block" } }}>
          FAQ'S
        </Button>
        <Button color="inherit" 
                component={Link} to="/contactos"
                sx={{ display: { xs: "none", md: "block" } }}>
          CONTACTOS
        </Button>
        {/* Submenu Equipa*/}
        <Menu anchorEl={submenuEquipaAnchorEl} 
              open={Boolean(submenuEquipaAnchorEl)} 
              onClose={handleSubmenuEquipaClose}
              MenuListProps={{ sx: { padding: 1 }, // Remove padding interno da lista
              }}>
          <MenuItem sx={menuItemStyle} onClick={handleSubmenuEquipaClose} component={Link} to="/quemsomos">A CHANCE LEILÕES</MenuItem>
          <MenuItem sx={menuItemStyle} onClick={handleSubmenuEquipaClose} component={Link} to="/equipa">A EQUIPA CHANCE LEILÕES</MenuItem>
        </Menu>

        {/* Submenu Regulamento*/}
        <Menu anchorEl={submenuRegulamentoAnchorEl} 
              open={Boolean(submenuRegulamentoAnchorEl)} 
              onClose={handleSubmenuRegulamentoClose}
              MenuListProps={{ sx: { padding: 1 }, // Remove padding interno da lista
              }}>
          <MenuItem sx={menuItemStyle} onClick={handleSubmenuRegulamentoClose} component={Link} to="/regcomprador">REGULAMENTO COMPRADOR </MenuItem>
          <MenuItem sx={menuItemStyle} onClick={handleSubmenuRegulamentoClose} component={Link} to="/reggeral">REGULAMENTO GERAL</MenuItem>
          <MenuItem sx={menuItemStyle} onClick={handleSubmenuRegulamentoClose} component={Link} to="/regvendedor">REGULAMENTO VENDEDOR</MenuItem>
          <MenuItem sx={menuItemStyle} onClick={handleSubmenuRegulamentoClose} component={Link} to="/privacidade">PRIVACIDADE</MenuItem>
          <MenuItem sx={menuItemStyle} onClick={handleSubmenuRegulamentoClose} component={Link} to="/infolegal">INFORMAÇÃO LEGAL</MenuItem>
          <MenuItem sx={menuItemStyle} onClick={handleSubmenuRegulamentoClose} component={Link} to="/reslitigios">RESOLUÇÃO ALTERNATIVA DE LITÍGIOS</MenuItem>
          <MenuItem sx={menuItemStyle} onClick={handleSubmenuRegulamentoClose} component={Link} to="https://www.livroreclamacoes.pt/inicio">LIVRO DE RECLAMAÇÕES</MenuItem>
        </Menu>

        {/* Menu Mobile */}
        <Menu anchorEl={mobileMenuOpen} 
              open={Boolean(mobileMenuOpen)} 
              onClose={handleMobileMenuClose}
              MenuListProps={{ sx: { padding: 1 }, // Remove padding interno da lista
              }}>
          <MenuItem sx={menuItemStyleMov} onClick={handleMobileMenuClose} component={Link} to="https://chanceleiloes.bidspirit.com/#home">LEILÕES</MenuItem>
          <MenuItem sx={menuItemStyle} onClick={handleSubmenuEquipaOpen}>A NOSSA EQUIPA &gt;</MenuItem>
          <MenuItem sx={menuItemStyle} onClick={handleSubmenuRegulamentoOpen}>REGULAMENTO &gt;</MenuItem>
          <MenuItem sx={menuItemStyle} onClick={handleMobileMenuClose} component={Link} to="/avaliacoes">AVALIAÇÕES</MenuItem>
          <MenuItem sx={menuItemStyle} onClick={handleMobileMenuClose} component={Link} to="/faqs">FAQ'S</MenuItem>
          <MenuItem sx={menuItemStyle} onClick={handleMobileMenuClose} component={Link} to="/contactos">CONTACTOS</MenuItem>
        </Menu>
      </Toolbar>
   
    </AppBar>
    
  );
};

export default MenuBarra;
