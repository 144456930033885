import Bg1 from "../images/bg1.jpg";
import Back from "../images/back.png";
import React from "react";
import {Typography} from "@mui/material";
function Slideshow() {
    return (
      <div style={{ backgroundImage: `url(${Bg1})`, height: "433px", position: "relative" }}>
      
        <div style={{
                height: "305px",
                backgroundImage: `url(${Back})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                position: "relative",
                left: "17%",
                top: "49%",
                transform: "translateY(-50%)",
                padding: "62px 16px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
              }}>
              <Typography variant="h4" sx={{ fontWeight: "bold", color: "#fff" }}>
                A CHANCE
              </Typography>
              <Typography variant="h4" sx={{ fontWeight: "bold", color: "#fff" }}>
                É SUA!
              </Typography>
              <Typography variant="h6" sx={{ color: "#ffeb3b" }}>
                TODAS AS OPORTUNIDADES
              </Typography>
              <Typography variant="h6" sx={{ color: "#ffeb3b" }}>
                A PARTIR DE 1 EURO
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: "bold", color: "#fff" }}>
                LICITE
              </Typography>
            </div>
      </div>
    );
  }
  
  export default Slideshow;