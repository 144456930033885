import { Box, Typography, Link } from "@mui/material";

const InformacaoLegal = () => {
  return (
    <Box sx={{ textAlign: "center", padding: "20px" }}>
      {/* Seção 1 - Contrastaria */}
      <Box sx={{ marginBottom: "30px" }}>
        <img 
          src="images/contrastaria.png" 
          alt="Contrastaria" 
          style={{ maxWidth: "100%", height: "auto" }}
        />
        <Box mt={2}>
          <Link 
            href="https://www.incm.pt/portal/uco_marcas.jsp" // Ajuste conforme a URL correta 
            sx={{ fontSize: "1.2rem", fontWeight: "bold", textDecoration: "none", color: "#0073e6" }}
          >
            Marcas legais das Contrastarias - informação completa
          </Link>
        </Box>
      </Box>

      {/* Seção 2 - Cotação Metais Preciosos */}
      <Box>
        <Typography variant="h5" sx={{ fontWeight: "bold", marginBottom: "10px" }}>
          Cotação Metais Preciosos
        </Typography>
        <Link 
          href="http://pt.bullion-rates.com/" // Ajuste conforme a URL correta
          sx={{ fontSize: "1rem", fontWeight: "bold", textDecoration: "none", color: "#0073e6" }}
        >
          Cotação metais preciosos
        </Link>
      </Box>
    </Box>
  );
};

export default InformacaoLegal;
