import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";

const RegulamentoGeral = () => {
  return (
    <Box sx={{ maxWidth: "900px", margin: "auto", padding: { xs: "10px", sm: "20px" } }}>
      
      <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
        CONDIÇÕES GERAIS DE COMPRA E VENDA E CONDIÇÕES NEGOCIAIS CHANCE LEILÕES
      </Typography>

      <Typography >
        A CHANCE LEILÕES rege a sua actividade de leiloeira, conforme as condições gerais e
            negociais expressas nos artigos seguintes, e ainda quaisquer outras condições especiais
            expressas, publicadas ou afixadas em local próprio antes da realização de qualquer
            leilão. As condições negociais para Compradores e Vendedores fazem parte das Condições
            Gerais da CHANCE LEILÕES.
      </Typography>

      <Typography variant="h6" sx={{ fontWeight: "bold", mt: 3 }}>I - CONDIÇÕES NEGOCIAIS PARA OS COMPRADORES</Typography>
      <Typography variant="subtitle1" sx={{ fontWeight: "bold", }}>Artº 1º</Typography>
      <Typography >
        No registo de inscrição no leilão é de preenchimento obrigatório o nome, a morada, o
                                número do telefone e o número de Cartão do Cidadão ou Bilhete de Identidade. Em
                                simultâneo com o registo, o Comprador dá o seu acordo às condições negociais da CHANCE
                                LEILÕES e expressa a sua concordância e aceitação das presentes Condições Gerais.
      </Typography>
      <Typography >
        A inscrição no leilão é reservada a maiores de 18 anos, sendo entendido que o Comprador
                                actue em seu nome pessoal e próprio. No acto da inscrição no leilão a CHANCE LEILÕES
                                poderá solicitar comprovativos dos documentos de identificação em vigor (Bilhete de
                                Identidade e/ou Número de Contribuinte) ao potencial comprador.
      </Typography>

      <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>Artº 2º</Typography>
      <Typography >
        MUITOS LOTES TEM UM PREÇO INICIAL DE 1€ sem qualquer tipo de reserva (preço mínimo)
                                que condicione a sua aquisição no decurso do leilão.Excepcionalmente, a CHANCE LEILÕES,
                                em lotes com características especiais, reserva-se o direito de introduzir uma reserva
                                mínima por ordem do vendedor.
      </Typography>

      <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>Artº 3º</Typography>
      <Typography >
        Todos os lotes colocados em venda nos Leilões que promovemos, estão em exposição nas
                                nossas instalações e disponíveis para serem manuseados, apreciados e examinados. Os
                                lotes encontram-se em exposição durante todo o período do leilão e nos horários
                                indicados. A CHANCE LEILÕES, sem prejuízo da descrição pormenorizada de cada um dos
                                lotes e das fotos que os acompanham, recomenda aos potenciais compradores, sempre que
                                possível, a análise presencial das peças.
      </Typography>

      <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>Artº 4º</Typography>
      <Typography pararaph>
        Todas as licitações são contratos de compra e venda celebrados e são considerados
                                definitivas e vinculativas. É considerado comprador pela CHANCE LEILÕES, o licitante do
                                leilão, que arrematar o lote pelo valor mais alto.
      </Typography>

      <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>Artº 5º</Typography>
      <Typography pararaph>
        As licitações serão realizadas "online", presencial ou telefónicamente, excepto na eventualidade extraordinária da realização de um leilão presencial que, neste caso, será
                                objecto de menção especial. As licitações ocorrerão de acordo com a seguinte tabela de
                                incremento de licitações.
      </Typography>

      <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>Tabela de Incrementos</Typography>
      
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><b>De</b></TableCell>
              <TableCell><b>A</b></TableCell>
              <TableCell><b>Incrementos</b></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[
              { de: "€ 1", ate: "€ 6", incremento: "€ 2" },
              { de: "€ 7", ate: "€ 9", incremento: "€ 3" },
              { de: "€ 10", ate: "€ 29", incremento: "€ 4" },
              { de: "€ 30", ate: "€ 59", incremento: "€ 5" },
              { de: "€ 60", ate: "€ 99", incremento: "€ 8" },
              { de: "€ 100", ate: "€ 199", incremento: "€ 10" },
              { de: "€ 200", ate: "€ 399", incremento: "€ 20" },
              { de: "€ 400", ate: "€ 999", incremento: "€ 50" },
              { de: "€ 1.000", ate: "€ 4.999", incremento: "€ 100" },
              { de: "> € 5.000", ate: "", incremento: "€ 250" },
            ].map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.de}</TableCell>
                <TableCell>{row.ate}</TableCell>
                <TableCell>{row.incremento}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
     
      <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>Artº 6º - Licitações Online, Telefónicas e Presenciais</Typography>
      <Typography sx={{ mb: 1 }}>
        a) Só são aceites licitações Online, após registo do potencial comprador e acordo prévio às condições negociais e gerais da CHANCE
                                LEILÕES.
      </Typography>
      <Typography sx={{ mb: 1 }}>
        b) Nem a CHANCE LEILÕES, nem os seus administradores ou colaboradores poderão ser responsabilizados por erros ou falhas na execução das licitações Online.
      </Typography>
      <Typography sx={{ mb: 1 }}>
        c) O licitante e potencial Cliente Comprador aceita que as suas licitações Online, no
                                website da CHANCE LEILÕES, são definitivas e não podem, por qualquer meio, ser anuladas
                                ou revogadas.
      </Typography>
      <Typography sx={{ mb: 1 }}>
        d) A licitação em cada um dos nossos leilões implica a atribuição individualizada e
                                única de um número de licitante exclusivo daquele Leilão, que poderá consultar na página
                                do leilão.
      </Typography>
      <Typography sx={{ mb: 1 }}>
        e) A CHANCE LEILÕES, reserva-se o direito de recomeçar qualquer leilão ou licitação, de
            um qualquer lote, se considerar que é apropriado e razoável fazê-lo.
      </Typography>
      <Typography >
        f) Em caso de lances de valor igual será aceite e validado o que tiver sido efectuado em
            primeiro lugar de acordo com o nosso registo informático.
      </Typography>
      <Typography sx={{ mb: 1 }}>
        g) As licitações telefónicas serão efectuadas através do Telefone nº 223294275.
      </Typography>
      <Typography sx={{ mb: 1 }}>
        i) A CHANCE LEILÕES promoverá a publicitação com a necessária antecedência de toda a
                                informação sobre o dia , hora de abertura, composição , características e data e hora de
                                encerramento dos leilões que empreender.
      </Typography>

      <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>Artº 7º - Oferta Máxima</Typography>
      <Typography  sx={{ mb: 1 }}>
        Caso não tenha a possibilidade de acompanhar o leilão, poderá fazer uma oferta máxima
                                que implicará a licitação automática do sistema, até ao montante máximo que definiu de
                                forma totalmente segura. A opção de oferta máxima permitir-lhe-á cobrir os lances de
                                outros licitantes, caso existam, sem necessidade de acompanhar o encerramento do
                                leilão.
      </Typography>      
      <Typography >
        A sua OFERTA MÁXIMA cobrirá progressivamente as ofertas de outros licitantes, desde que
                                as outras licitações não ultrapassem o valor da sua oferta máxima. Através deste
                                sistema, poderá comprar pelo valor da penúltima licitação mais um lance.
      </Typography>    

      <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>Artº 8º</Typography>
      <Typography>
      A CHANCE LEILÕES reserva-se do poder discricionário de recusar a admissão, presença ou
                                inscrição no leilão e ainda de ignorar qualquer tipo de licitação, designadamente a quem
                                não tiver pontualmente cumprido obrigações de, pagamento e levantamento de um ou mais
                                bens, em leilões anteriores.
      </Typography>   

      <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>Artº 9º</Typography>
      <Typography  sx={{ mb: 1 }}>
        a) Sobre o valor da arrematação final incide a NOSSA COMISSÃO DE 18% acrescida do IVA à
                                taxa legal em vigor com uma comissão mínima de 2€ acrescido do IVA respectivo. 
                                Qualquer eventual alteração de comissionamento a aplicar por parte
                                da CHANCE LEILÕES, exige menção especial na documentação de apoio e no catálogo do
                                leilão em causa.
      </Typography>      
      <Typography >
        b) Poderá, após o pagamento do lote e suportando o custo de 8€ (para objectos até 
                                2 kg sem fragilidade que não exigam custos com embalemento especial), requerer o envio 
                                das peças via express mail* (Portugal Continental). Objectos especiais podem determinar 
                                a cobrança de uma taxa de enfardamento e embalamento especial. Envio para Madeira e 
                                Açores sujeito a orçamento prévio. 
      </Typography>    

      <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>II - PAGAMENTOS E LEVANTAMENTOS</Typography>
      <Typography variant="subtitle1" sx={{ fontWeight: "bold",  }}>Artº 10º</Typography>
      <Typography >
        A CHANCE LEILÕES facultará ao licitante vencedor a respectiva conta no prazo de 5 dias
                                após a realizaçao do leilão, dispondo o Cliente do prazo legal de oito dias úteis após a
                                recepção do aviso de pagamento para efetuar o pagamento e o levantamento da mesma. O
                                preço final a pagar será o valor da arrematação final, acrescido da comissão da CHANCE
                                LEILÕES no valor de 18% (comissão mínima de 2€) acrescido do IVA à taxa legal em vigor.
      </Typography>    

      <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>Artº 11º</Typography>
      <Typography  sx={{ mb: 1 }}>
        O levantamento do lote adquirido pressupõe o pagamento prévio e integral do respectivo
        preço, pelo que, só poderá ser efectuado após confirmação da sua efectiva cobrança.
      </Typography>      
      <Typography  sx={{ mb: 1 }}>
        O levantamento físico dos lotes, por motivos de eficiência e celeridade, deverá ser antecedido 
        de marcação prévia (geral@chanceleiloes.com ou 223294275).
      </Typography> 
      <Typography>
        A titularidade do lote ou lotes arrematados não se transferirá para o Comprador enquanto
        este não tiver realizado o pagamento integral do montante total em dívida.
      </Typography> 
      <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>Artº 12º</Typography>
      <Typography >
        O comprador deverá à sua custa e sob a sua responsabilidade diligenciar o manuseamento,
                                embalamento, levantamento e transporte dos bens adquiridos, no prazo de dez (10) dias
                                úteis após a compra e o consequente e efectivo pagamento dos lotes adquiridos. A CHANCE
                                LEILÕES prestará toda a sua colaboração ao levantamento dos lotes mas não lhe poderá ser
                                atribuída qualquer tipo de responsabilidade na eventualidade de surgir qualquer tipo de
                                dano, ainda que provocado por negligência. A eventual indicação ou sugestão de uma
                                empresa ou pessoa para a realização do levantamento e transporte dos lotes exclui,
                                igualmente, qualquer responsabilidade da “CHANCE LEILÕES”, seus representantes ou seus
                                colaboradores, por quaisquer danos ou prejuízos decorrentes desses serviços.
                                Sendo os lotes adquiridos enviados por transportadora e, sem prejuízo do manuseamento e embalamento 
                                da Nossa responsabilidade, os riscos de quebra ou dano registados durante esse transporte são da 
                                exclusiva responsabilidade do Comprador (um seguro de transporte poderá ser contratado para prevenir eventuais prejuízos)
      </Typography>
      <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>Artº 13º</Typography>
      <Typography >
        Se o comprador não efectuar o levantamento do bem adquirido no prazo de dez (10) dias
                                úteis contados da data da respectiva compra, ficará responsável pela perda ou dano,
                                incluindo furto ou roubo, que possa ocorrer no bem em causa. A CHANCE LEILÕES reserva-se
                                o direito de transferir e armazenar o lote noutras instalações ao seu critério, a custas
                                e sob a responsabilidade do Comprador, único responsável por todos os custos a que haja
                                lugar com o manuseamento, o embalamento a remoção, transporte, armazenamento e seguro do
                                mesmo.
                                Lotes liquidados ou retirados e não levantados num prazo de 4 meses serão , de acordo com a sua natureza, 
                                colocados em Leilão ou doados a Instituições Solidariedade Social.
      </Typography>
      <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>Artº 14º</Typography>
      <Typography sx={{ mb: 1 }}>
        Na eventualidade de existirem situações de incumprimento e se algum lote não for
                                integralmente pago e levantado nos prazos e nas condições estabelecidas nos artigos
                                anteriores, a "CHANCE LEILÕES", na qualidade de representante do Vendedor, poderá, sem
                                prejuízo de quaisquer outros direitos de que seja titular, adoptar uma ou várias das
                                seguintes acções:
      </Typography>
      <Typography sx={{ mb: 1 }}>
        a) Actuar judicialmente contra o Comprador com vista a ser ressarcido dos prejuízos
            emergentes do incumprimento do contrato.
      </Typography>
      <Typography sx={{ mb: 1 }}>
        b) Cobrar juros de mora à taxa legal sobre o montante total em dívida (preço de
        arrematação acrescido do comissionamento) até ao momento do seu integral recebimento.
      </Typography>
      <Typography sx={{ mb: 1 }}>
        c) Resolver o contrato tendo por objecto o lote ou lotes vendidos ao Comprador faltoso,
                                no leilão em causa ou em qualquer outro, caso o montante total em dívida e/ou o
                                levantamento dos lotes não seja realizado no prazo adicional de 10 (dez) dias concedido
                                aqui expressamente pela CHANCE LEILÕES como última oportunidade de cumprimento. Tal
                                prazo será contado a partir do dia imediato ao termo do prazo legal fixado para
                                pagamento no artº 10 das presentes Condições Negociais.
      </Typography>
      <Typography sx={{ mb: 1 }}>
        d) Vender novamente o lote em causa ou providenciar para que seja vendido, de novo, em
                                leilão ou proceder à venda por negociação particular, caso em que o Comprador faltoso
                                deverá pagar à CHANCE LEILÕES qualquer importância que permaneça em débito com
                                referência ao montante total em dívida, nomeadamente o comissionamento fixado, acrescido
                                dos custos decorrentes da remoção, transporte e armazenamento do lote.
      </Typography>
      <Typography>
        e) Remover, armazenar e segurar o lote ou lotes à custa do Comprador faltoso cabendo à
                                CHANCE LEILÕES decidir se o armazenamento ocorrerá nos seus armazéns ou noutros à sua
                                escolha.
      </Typography>
      <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>III - RESPONSABILIDADE DA CHANCE LEILÕES</Typography>
      <Typography variant="subtitle1" sx={{ fontWeight: "bold", }}>Artº 15º</Typography>
      <Typography sx={{ mb: 1 }}>
        Os bens levados a leilão são vendidos no estado de conservação em que se encontram,
                                sendo da responsabilidade dos potenciais compradores analisar e confirmar pessoalmente,
                                durante a exposição dos mesmos, que se realiza nos dias anteriores ao leilão, o rigor da
                                descrição constante do catálogo, nomeadamente, a autenticidade, o tipo de mecanismo, a
                                garantia, eventuais restauros, defeitos, faltas e imperfeições que se mencionem no mesmo.
      </Typography>  
      <Typography>
        A CHANCE LEILÕES , não obstante a enorme variedade dos bens aceites para serem vendidos
                                em leilão, sustentará a descrição dos bens nas informações prestadas pelos respectivos
                                proprietários actuando na qualidade de seu representante.
      </Typography>
      <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>Artº 16º</Typography>
      <Typography sx={{ mb: 1 }}>
        1 - Considerando a enorme variedade dos bens aceites para serem vendidos em leilão e a
                                sua mais diversa natureza e tipologia, os lotes com os bens respectivos são vendidos no
                                estado em que se encontram, com defeitos, imperfeições e eventuais erros ou omissões de
                                descrição.
      </Typography>  
      <Typography sx={{ mb: 1 }}>
        2 - As eventuais ilustrações ou representações em catálogos destinam-se exclusivamente à
            identificação dos bens sujeitos a venda.
      </Typography>  
      <Typography sx={{ mb: 1 }}>
        3 - Nem a CHANCE LEILÕES, nem os seus representantes ou colaboradores poderão ser
                                responsabilizados por erros relacionados com a descrição, genuinidade ou autenticidade
                                de qualquer lote uma vez que nenhuma garantia, a tal respeito, é dada pela CHANCE
                                LEILÕES, seus representantes ou colaboradores.
      </Typography>  
      <Typography sx={{ mb: 1 }}>
        4 - Se, porventura, vier a demonstrar-se que algum lote constituiu uma falsificação
                                deliberada, tal lote poderá ser devolvido pelo Comprador à CHANCE LEILÕES no prazo de 20
                                (vinte) dias contados do dia da realização do leilão, devendo a devolução ocorrer no
                                mesmo estado em que o lote se encontrava à data do leilão, acompanhado de um relatório,
                                certificado por entidade de reconhecida credibilidade, dos defeitos e das falsificações
                                do lote, do seu número e da data do leilão em que foi adquirido.
      </Typography>  
      <Typography >
        5 - Se a CHANCE LEILÕES confirmar inequivocamente que se trata de uma falsificação
                                deliberada e o Comprador ainda for dono do bem adquirido, a compra e venda será
                                considerada nula e de nenhum efeito e o montante do preço devolvido sem que o Vendedor a
                                isso se possa opor.
      </Typography>  
      <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>CONDIÇÕES NEGOCIAIS PARA OS VENDEDORES</Typography>
      <Typography variant="subtitle1" sx={{ fontWeight: "bold", }}>Artº 17º</Typography>
      <Typography sx={{ mb: 1 }}>
        A CHANCE LEILÕES actua na venda na qualidade de representante do Vendedor, não podendo,
            por isso, ser responsabilizado pelo Vendedor ou Comprador por qualquer falta ou omissão.
      </Typography>  
      <Typography >
        A partir do momento em que um vendedor e a CHANCE LEILÕES assinem um contrato de
                                prestação de serviços, ficam ambos automaticamente vinculados ao referido contrato de
                                prestação de serviços.
      </Typography>  
      <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>Artº 18º</Typography>
      <Typography sx={{ mb: 1 }}>
        1 - As peças vendidas em leilão são colocadas ao abrigo de contrato de prestação de
            serviços celebrado entre o Vendedor e a CHANCE LEILÕES
      </Typography>  
      <Typography  sx={{ mb: 1 }}>
        2 - O Vendedor obriga-se a informar a CHANCE LEILÕES sobre a respectiva titularidade da
            peça, nomeadamente a que título foi adquirida e respectiva proveniência.
      </Typography>  
      <Typography sx={{ mb: 1 }}>
        3 - O Vendedor garante à CHANCE LEILÕES e ao Comprador que é legítimo dono do bem ou que
            está legalmente autorizado pelo dono a vender tal bem.
      </Typography>  
      <Typography>
        4 - O Vendedor assume a obrigação de indemnizar a CHANCE LEILÕES, seus representantes,
                                trabalhadores ou colaboradores, bem como o Comprador por qualquer dano ou prejuízo que
                                sofram em consequência do incumprimento de algumas das disposições previstas nos números
                                anteriores.
      </Typography>  

      <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>Artº 19º</Typography>
      <Typography sx={{ mb: 1 }}>
        1 - Por expressa vontade do vendedor, a CHANCE LEILÕES poderá segurar os bens em seu
                                poder para venda em leilão. Nestes casos, o Vendedor suportará integralmente o prémio de
                                seguro e autoriza a CHANCE LEILÕES a deduzir ao preço de martelo o montante referente ao
                                prémio de seguro.
      </Typography>
      <Typography sx={{ mb: 1 }}>
        2 - O seguro respeitante a cada peça será efectuado pelo valor de mercado que a CHANCE
                                LEILÕES lhe atribui e vigorará até ao momento da concretização da venda com o efectivo
                                pagamento pelo Comprador.
      </Typography>  
      <Typography>
        3 - Nos casos em que por ausência de vontade expressa do vendedor não seja efectuado o
                                seguro, será o Vendedor o único responsável por eventuais sinistros, e nem a CHANCE
                                LEILÕES nem qualquer seu Representante ou Colaborador poderá ser responsabilizado por
                                qualquer perda ou dano, ainda que provocados por negligência ou por qualquer outra causa.
      </Typography>  
      <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>Artº 20º</Typography>
      <Typography>
        Se, antes da CHANCE LEILÕES enviar a quantia devida ao vendedor pela venda dos seus
                                lotes, o Comprador formalizar a pretensão de resolver o contrato nos termos e de acordo
                                com o Artigo 16º e se a CHANCE LEILÕES entender que o Comprador tem razão, poderá
                                resolver o contrato de compra e venda e restituir ao Comprador as importâncias entregues
                                com respeito ao lote ou lotes em causa.
      </Typography>
      <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>Artº 21º</Typography>
      <Typography sx={{ mb: 1 }}>
         1 - O Vendedor autoriza a CHANCE LEILÕES a deduzir do Preço de Martelo o montante das
                                comissões de manuseamento, organização, avaliação e promoção acordadas no contrato de
                                prestação de serviços (Consignação para venda de bens em leilão) e que lhe são devidas,
                                assim como, o montante de eventuais despesas adicionais.
                                A comissão terá sempre um valor mínimo, por lote, de 2€ (acrescido da taxa de IVA em vigor).
      </Typography>
      <Typography sx={{ mb: 1 }}>
        2 - A CHANCE LEILÕES enviará ao Vendedor a quantia devida pela venda dos lotes até 20
            (vinte) dias após a sessão do leilão em que ocorreu a arrematação do lote em causa.
      </Typography>
      <Typography sx={{ mb: 1 }}>
        3 - Na eventualidade de a CHANCE LEILÕES não ter recebido do Comprador o montante total
                                em dívida até à data referida no número anterior, a CHANCE LEILÕES enviará a quantia
                                devida no prazo de 5 (cinco) dias úteis a contar da data em que tenha recebido do
                                Comprador o montante total em dívida.
      </Typography>
      <Typography >
        4 - O Vendedor autoriza expressamente a CHANCE LEILÕES a deduzir do montante líquido que
                                lhe seria devido pela venda de bens, as quantias pelo mesmo devidas enquanto Comprador
                                de outros bens, operando-se nessa medida a compensação entre os créditos existentes.
      </Typography>
      <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>Artº 22º</Typography>
      <Typography sx={{ mb: 1 }}>
        1 - Se o Comprador não pagar à CHANCE LEILÕES o montante total em dívida no prazo de 20
                                (vinte) dias a contar da data de realização do leilão, a CHANCE LEILÕES procurará
                                ponderar e decidir, conjuntamente com o Vendedor, as condutas mais adequadas a tomar,
                                para obter o pagamento do montante total em dívida.
      </Typography>
      <Typography sx={{ mb: 1 }}>
        Em caso algum poderá a “CHANCE LEILÕES”, ser responsabilizada por qualquer vendedor pela
                                falta de pagamento ou desistência de algum comprador relativamente a quaisquer lotes
                                arrematados em leilão.
      </Typography>
      <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>Artº 23º</Typography>
      <Typography>
        Se um Vendedor proceder ao cancelamento do Contrato de Venda, a CHANCE LEILÕES
                                reserva-se o direito de cobrar uma taxa correspondente a 10% da última estimativa ou
                                estimativa média do preço do leilão, do bem retirado ao tempo do cancelamento, acrescida
                                de IVA à taxa legal e das Despesas efectuadas, imputáveis ao bem em causa.
      </Typography>
      <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>Artº 24º</Typography>
      <Typography sx={{ mb: 1 }}>
        1 - Se não se consumar a venda de um lote por ausência de licitações, a CHANCE LEILÕES
                                comunicará esse facto ao Vendedor num prazo máximo de 5 (cinco) dias úteis após a
                                realização do leilão, podendo aquele optar por voltar a oferecer o lote para venda ou
                                levantá-lo, mediante o pagamento das despesas devidas.
      </Typography>
      <Typography >
        2 - O Vendedor deverá num prazo máximo de dez (10) dias decidir se pretende voltar a
                                colocar o lote para venda ou se prefere levanta-lo. Se nada comunicar à CHANCE LEILÕES ,
                                esta poderá continuar a promover a venda do bem , sendo que o Vendedor ficará
                                responsável por quaisquer despesas de remoção, armazenamento ou seguro do lote.
      </Typography>
      <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>Artº 25º</Typography>
      <Typography >
        O Vendedor concede à CHANCE LEILÕES o direito de fotografar e publicar, sob qualquer
                                forma, textos, fotografias ou ilustrações de qualquer tipo, tendo como objecto qualquer
                                lote na posse da CHANCE LEILÕES para ser vendido, assim como de usar tais textos,
                                fotografias ou ilustrações de qualquer espécie, bem como quaisquer outros ou outras
                                fornecidas pelo Vendedor, por sua iniciativa, em qualquer momento, ligado ou não à
                                realização do leilão.
      </Typography>
      <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>Artº 26º</Typography>
      <Typography sx={{ mb: 1 }}>
        1 - Se o bem vendido em leilão corresponder a uma Obra de Arte Original que não seja de
                                arquitectura nem de arte aplicada, o autor da obra, que não seja o Vendedor, terá
                                direito, nos termos do art. 54.º, n.º 1, do Código do Direito de Autor, a uma
                                participação sobre o preço obtido na venda, livre de impostos.
      </Typography>
      <Typography sx={{ mb: 1 }}>
        2 - Nos termos do art. 54.º, n.º 7 do Código do Direito de Autor, o pagamento da
                                participação referida nesta cláusula é da responsabilidade do Vendedor da Obra de Arte
                                Original, pelo que o Vendedor se compromete a entregar ao autor da obra a quantia
                                respectiva.
      </Typography>
      <Typography sx={{ mb: 1 }}>
        3 - No caso de o autor da Obra de Arte Original contactar a CHANCE LEILÕES com vista ao
                                pagamento da participação referida nesta cláusula, a CHANCE LEILÕES informará o autor da
                                identificação e dados de contacto do Vendedor e dos termos em que se processou a venda,
                                para que o autor possa exercer o seu direito legal junto do Vendedor.
      </Typography>
      <Typography sx={{ mb: 1 }}>
        4 - A participação referida no nº 1 é fixada, nos termos do art. 54.º, n.º 4 do Código
            do Direito de Autor, do seguinte modo, não podendo nunca exceder € 12 500:
      </Typography>
      <Typography sx={{ mb: 1 }}>
        a) 4% sobre o preço de venda cujo montante esteja compreendido entre €3.000 e €50.000;
      </Typography>
      <Typography sx={{ mb: 1 }}>
        b) 3% sobre o preço de venda cujo montante esteja compreendido entre €50.000,01 e €200.000;
      </Typography>
      <Typography sx={{ mb: 1 }}>
        c) 1% sobre o preço de venda cujo montante esteja compreendido entre €200.000,01 e €350.000;
      </Typography>
      <Typography sx={{ mb: 1 }}>
        d) 0,5% sobre o preço de venda cujo montante esteja compreendido entre €350.000,01 e €500.000;
      </Typography>
      <Typography >
        e) 0,25% sobre o preço de venda cujo montante seja superior a €500.000,01
      </Typography>
     
      <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>GARANTIAS</Typography>

      <Typography variant="subtitle1" sx={{ fontWeight: "bold", }}>Artº 27º</Typography>
      <Typography >
        1 - Todas as informações pessoais decorrentes do registo e da compra e venda de lotes em
            leilão, são, sem prejuízo das obrigações legais, estrita e rigorosamente confidenciais
      </Typography>
      <Typography sx={{ mb: 1 }}>
        2 - A CHANCE LEILÕES obriga-se a uma descrição pormenorizada dos lotes a leilão visando
                                a sua adequada catalogação. Para tal, suportar-se-á nas informações sobre as peças
                                prestadas pelos vendedores assim como nos pareceres dos consultores técnicos e
                                avaliadores que cooperam com a CHANCE LEILÕES na análise dos lotes em venda.
      </Typography>
      <Typography sx={{ mb: 1 }}>
        3 - A CHANCE LEILÕES, nem os seus Gerentes e Colaboradores,conjuges, ascendentes ou
                                descendentes no 1º grau, podem em caso algum e em nenhuma circunstância, licitar ou
                                actuar em nome próprio como compradores dos bens apresentados em leilão.
      </Typography>
      <Typography sx={{ mb: 1 }}>
        4 - A licitação em cada um dos Leilões realizados pela CHANCE LEILÕES, implica a
                                atribuição individualizada e única de um número de licitante exclusivo daquele Leilão,
                                que poderá consultar.
      </Typography>
      <Typography >
        5 - A CHANCE LEILÕES dispõe de livro de reclamações.
      </Typography>

      <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>DISPOSIÇÕES FINAIS</Typography>
      <Typography variant="subtitle1" sx={{ fontWeight: "bold", }}>Artº 28º</Typography>
      <Typography >
        1 - Qualquer representação ou declaração da CHANCE LEILÕES, em qualquer catálogo, no que
                                respeita à autoria, atribuição, genuinidade, origem, data, idade, proveniência, estado
                                ou estimativa de preço de venda, deverá ser entendida como mera emissão de opinião.
                                Qualquer interessado deverá sempre atender, ao seu próprio juízo de valor, no que se
                                reporta às matérias acima enunciadas e nem a CHANCE LEILÕES nem os seus representantes
                                ou colaboradores, são responsáveis pela correcção de tais juízos de valor.
      </Typography>
      <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>Artº 29º</Typography>
      <Typography sx={{ mb: 1 }}>
        1 - O Comprador ou Vendedor expressam e consentem no processamento dos seus dados
                                pessoais nos termos da Lei 67/98, de 26 de Outubro, processamento este que se insere no
                                âmbito da autorização de isenção n.º 1/99.
      </Typography>
      <Typography sx={{ mb: 1 }}>
        2 - Os dados pessoais do Comprador ou Vendedor serão recolhidos e processados para
                                efeitos do processamento das obrigações contratuais da CHANCE LEILÕES LDA, bem como ao
                                envio de informação sobre leilões e/ou outros eventos organizados pela CHANCE LEILÕES.
      </Typography>
      <Typography sx={{ mb: 1 }}>
        3 - O Comprador ou Vendedor terão o direito de acesso e informação relativamente aos seus dados pessoais.
      </Typography>
      <Typography >
        4 - Para aceder aos seus dados pessoais e requerer a sua alteração, rectificação ou
                                eliminação, o Comprador ou Vendedor poderão fazê-lo através do envio de uma carta para
                                Rua Afonso Lopes Vieira, 208, 4100-020 ou geral@chanceleiloes.com
      </Typography>
      <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>Artº 30º</Typography>
      <Typography >
        Para toda e qualquer questão emergente das presentes condições de venda, será competente
            o foro da Comarca do Porto, com expressa renúncia a qualquer outro.
      </Typography>

    </Box>
  );
};

export default RegulamentoGeral;
