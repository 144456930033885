import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HashRouter as Router } from 'react-router-dom';
//<Router basename="/p/pkHPJtg7_qWWYeD5bfh_HE1F1XQlTVfS0nq9Swoh95A3tmlS1R54ZeoZSxjDacia/n/frussd5az6tj/b/chanceLeiloes/o/">
// Oracle Cloud Infraestruture Bucket chanceLeiloes
//   <Route path="/" element={<Home />}  />
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
    
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
