import React from "react";
import {Box, Typography, useTheme,useMediaQuery} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Link } from "react-router-dom";
import  LogoChance from "../images/logo_chance.png";
import  BotaoTopo from "../images/botao_topo.png";

  const Header = ({ corpo_01, corpo_01_start, corpo_02, corpo_02_start, corpo_03_start,  }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md")); // Detecta mobile (md e abaixo)
 
  return (
    <Box sx={{ backgroundColor: "white" }}>
      {/* Barra superior */}
      <Grid container sx={{ backgroundColor: "#91791c" }}>
        <Grid item xs={12}>
          <input
            type="text"
            value=" "
            disabled
            readOnly
            style={{
              width: "100%",
              backgroundColor: "#91791c",
              color: "#91791c",
              border: "none",
              height: "30px",
              cursor: "default",
            }}
          />
        </Grid>
      </Grid>

      <Grid
      container
      sx={{ maxHeight: "95px" }} 
      justifyContent={isMobile ? "left" : "space-between"} // Centraliza no mobile, distribui no desktop
      alignItems="center"
    >
      {/* Exibe apenas o primeiro item no mobile */}
      {(isMobile ? [
          <Grid item xs={12} textAlign="left" pr={2}style={{width: "40%",}}>
            <Link to="https://chanceleiloes.bidspirit.com/#home">
              <img src={BotaoTopo} alt="Licite" style={{ height: "80px" }} />
            </Link>
          </Grid>] : [
            <Grid item xs={6}  >
            <img src={LogoChance} alt="Logo" style={{ height: "65px" }} />
        </Grid>, 
          <Grid item xs={4} textAlign="center" >
            <Typography variant="h5" sx={{ color: "#91791c", fontWeight: "bold" }}>
              LICITE
            </Typography>
            <Typography variant="h6">LOTES A PARTIR DE 1 €</Typography>       
          </Grid>, 
            <Link to="https://chanceleiloes.bidspirit.com/#home">
              <img src={BotaoTopo} alt="Leilão" style={{ height: "85px" }} />
            </Link>]).map((item, index) => (
              <Grid item xs={12} md={4} key={index} sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography variant="h8">{item}</Typography>
              </Grid>
            ))}
    </Grid>
  

    </Box>
  );
};

export default Header;
