import React from "react";
import {Box, Typography, Container, Button } from "@mui/material";
import {Link} from "react-router-dom";
import Grid from "@mui/material/Grid2";
const Banner = () => {
  return (
    <Box sx={{ backgroundColor: "#f5f5f5", padding: "20", margin: "0",  }}>
      <Grid container spacing={0} justifyContent="center">
        <Grid item xs={12}>
          <Container maxWidth="lg">
            <Grid container spacing={3} justifyContent="center" alignItems="center" sx={{ marginTop: "20px" }}>
              <Grid item xs={12} sm={6} md={3} component={Link} to="/quemsomos" color="black">
                <Box textAlign="center" >
                  <img src="images/ban1.png" alt="A Galeria" style={{ width: "100%" }} />
                  <Typography variant="h6" sx={{ fontWeight: "bold", marginTop: "8px" }}>
                    A GALERIA
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={3} component={Link} to="https://chanceleiloes.bidspirit.com/#home" color="black">
                <Box textAlign="center" >
                  <img src="images/ban2.png" alt="Leilão a Decorrer" style={{ width: "75%" }} />
                  <Typography variant="h6" sx={{ fontWeight: "bold", marginTop: "8px" }}>
                    LEILÃO A DECORRER
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={3} component={Link} to="https://chanceleiloes.bidspirit.com/#home" color="black">
                <Box textAlign="center">
                  <img src="images/ban3.png" alt="Leilão em Andamento" style={{ width: "75%" }} />
                  <Typography variant="h6" sx={{ fontWeight: "bold", marginTop: "8px" }}>
                    LEILÃO A DECORRER
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={3} component={Link} to="/equipa">
                <Box textAlign="center"  color="black">
                  <img src="images/ban4.png" alt="A Equipa" style={{ width: "100%" }} />
                  <Typography variant="h6" sx={{ fontWeight: "bold", marginTop: "8px" }}>
                    A EQUIPA
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Grid>

        {/* Footer Simples */}
        <Grid item xs={12} sx={{ backgroundColor: "#333", padding: "0px 0" }}>
          <Grid container spacing={4} justifyContent="center">
            <Grid item mt="10px">
              <Button color="inherit" 
                      component={Link} to="https://chanceleiloes.bidspirit.com/#home"
                      sx={{ display: { xs: "none", md: "block" } }}>
                      <img src="images/botao_regist_footer.png" alt="Registo" />
              </Button>
            </Grid>
            <Grid item>
              <Button color="inherit" 
                      component={Link} to="/avaliacoes"
                      sx={{ display: { xs: "none", md: "block" } }}>
                      <img src="images/botao_avaliacoes_footer.png" alt="Avaliações" />
              </Button>
            </Grid>
            <Grid item mt="10px">             
              <Button color="inherit" 
                 component={Link} to="https://chanceleiloes.bidspirit.com/#home"
                 sx={{ display: { xs: "none", md: "block" } }}>
                  <img src="images/botao_participe_footer.png" alt="Participe" />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Banner;
