
import Header from './components/Header';
import MenuBarra from "./components/MenuBarra";
import QuemSomos from "./components/QuemSomos";
import Footer from "./components/Footer";
//import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import {  Routes, Route, Navigate } from "react-router-dom";
import Home from "./components/Home"; // Páginas fictícias
import RegulamentoComprador from "./components/RegulamentoComprador";
import RegulamentoGeral from "./components/RegulamentoGeral";
import RegulamentoVendedor from "./components/RegulamentoVendedor";
import Avaliacoes from "./components/Avaliacoes";
import Equipa from "./components/Equipa";
import Privacidade from "./components/Privacidade";
import InformacaoLegal from "./components/InformacaoLegal";
import ResolucaoLitigios from "./components/ResolucaoLitigios";
import Faqs from "./components/Faqs";
import Contactos from "./components/Contactos";

//<Router basename="/p/pkHPJtg7_qWWYeD5bfh_HE1F1XQlTVfS0nq9Swoh95A3tmlS1R54ZeoZSxjDacia/n/frussd5az6tj/b/chanceLeiloes/o/">
// Oracle Cloud Infraestruture Bucket chanceLeiloes
//   <Route path="/" element={<Home />}  />
function App() {
 
   return (
    <div>
      <Header/>
      <MenuBarra/>
      <Routes>   
        <Route exact path="/" element={<Home/>}  />    
        <Route path="/avaliacoes" element={<Avaliacoes/>} />
        <Route path="/quemsomos" element={<QuemSomos />} />
        <Route path="/equipa" element={<Equipa />} />
        <Route path="/regcomprador" element={<RegulamentoComprador />} />
        <Route path="/reggeral" element={<RegulamentoGeral />} />
        <Route path="/regvendedor" element={<RegulamentoVendedor />} />
        <Route path="/privacidade" element={<Privacidade />} />
        <Route path="/infolegal" element={<InformacaoLegal />} />
        <Route path="/reslitigios" element={<ResolucaoLitigios />} />
        <Route path="/faqs" element={<Faqs/>} />
        <Route path="/contactos" element={<Contactos/>} />
        <Route path="/leiloes" element={<Navigate to="https://chanceleiloes.bidspirit.com/#home" replace />}/>
        <Route path="*" element={<Navigate to="/" />} />
    </Routes>
    <Footer/>
  </div>
);
  
}

export default App;
