import { Box, Typography } from "@mui/material";

const RegulamentoVendedor = () => {
  return (
    <Box sx={{ maxWidth: "900px", margin: "auto", padding: { xs: "10px", sm: "20px" } }}>
      <Typography variant="h6" sx={{ mb: 1, fontWeight: "bold" }}>
        Porquê escolher a CHANCE LEILÕES para vender as minhas peças?
      </Typography>

      <Typography align="left" sx={{ mb: 1 }}>
        A CHANCE LEILÕES dispõe já de uma seleccionada base de Clientes, nacionais e
                                estrangeiros, capazes de valorizar as suas peças, distinguir as suas virtualidades,
                                encontrar o Cliente ideal e conferir-lhe ao seu lote o valor efectivo de mercado.
      </Typography>

      <Typography align="left" sx={{ mb: 1 }}>
        A possibilidade de licitação a partir de 1 € atrai uma numerosa e vastíssima massa de
                                Clientes e tenderá a valorizar e maximizar o preço do seu lote, sem prejuízo de em lotes 
                                com características especiais e de maior valor unitário, a CHANCE LEILÕES introduzir por 
                                determinação do vendedor, um preço mínimo de reserva.
      </Typography>

      <Typography align="left" sx={{ mb: 2 }}>
      A Nossa ambição é a de nos impormos pelo rigoroso controlo na captação das peças para
                                venda, na criteriosa selecção dos lotes de cada leilão e na correcta catalogação dos
                                lotes em venda.
      </Typography>
      <Typography variant="h6" sx={{ mb: 0, fontWeight: "bold" }}>
      Principais Condições Relativas aos Vendedores
      </Typography>

      <Typography align="left" sx={{ mb: 1 }}>
      O vendedor de um bem e a CHANCE LEILÕES estão vinculados entre si a partir do momento em
      que seja assinado por ambas as partes um contrato de prestação de serviços.
      </Typography>

      <Typography align="left" sx={{ mb: 1 }}>
      O vendedor (ou o seu representante) garante ser proprietário e legítimo possuidor do bem
                                e é da sua responsabilidade a descrição da cronologia das peças colocadas em leilão, que
                                são sempre comunicadas às autoridades competentes.
      </Typography>
      <Typography align="left" sx={{ mb: 1 }}>
      O transporte e o depósito nas instalações da CHANCE LEILÕES e o seu posterior
                                levantamento em caso de não venda são da inteira responsabilidade do vendedor. A CHANCE
                                LEILÕES permite aos Vendedores para cobrirem o risco de perdas ou danos (incluindo furto
                                ou roubo) a inclusão numa apólice existente para o efeito, com a condição de previamente
                                o vendedor solicitar a inclusão das suas peças no respectivo seguro (no caso do vendedor
                                não ter pedido o seguro, não haverá neste caso indemnização pelos prejuízos ocorridos).
      </Typography>
      <Typography align="left" sx={{ mb: 1 }}>
      O vendedor autoriza a CHANCE LEILÕES a deduzir ao contrato de arrematação a comissão
                                devida por avaliação e promoção dos lotes adquiridos acrescida de IVA à taxa legal em
                                vigor , assim como os outros custos previstos nos termos do contrato.
      </Typography>
      <Typography align="left" sx={{ mb: 1 }}>
      O pagamento pela CHANCE LEILÕES ocorrerá no prazo limite de 20 (vinte) dias a partir da
      sua arrematação e na condição do comprador ter liquidado o valor total da venda.
      </Typography>
      <Typography align="left" sx={{ mb: 2 }}>
      O vendedor obriga-se a respeitar o código do Direito de Autor.
      </Typography>
      <Typography align="left" sx={{ mb: 1, fontWeight: "bold"  }}>
      Após o Leilão
      </Typography>
      <Typography align="left" sx={{ mb: 1 }}>
      No prazo limite de 20 (vinte) dias úteis após a arrematação final dos lotes, o vendedor
                                poderá passar nas nossas instalações para receber o valor líquido das peças vendidas
                                (preço de martelo menos comissões contratuais e IVA referente) e levantar as peças não
                                vendidas. Decorrido o prazo contratual para levantamento das peças serão aplicadas as
                                regras das nossas condições gerais contratuais.
      </Typography>
      <Typography align="left" sx={{ mb: 1 }}>
      A CHANCE LEILÕES deduzirá uma comissão de avaliação, manuseamento e promoção sobre as
                                peças vendidas, préviamente acordada e fixada no contrato de prestação de serviços, 
                                cujo valor mínimo, por lote, será sempre de 2€ (acrescido da taxa de IVA em vigor).
      </Typography>
      <Typography align="left" sx={{ mb: 1 }}>
      <b>AVALIADORES, ESPECIALISTAS E PERITOS REPUTADOS NAS MAIS DIVERSAS ÁREAS DE ACTIVIDADE
      - ANTIGUIDADES, OURO PRATA, MÓVEIS, PINTURA, FILATELIA, NUMISMÁTICA, FAIANÇAS E LIVROS</b>
      </Typography>
      <Typography align="left" sx={{ mb: 1 }}>
      Os nossos Consultores permanentes e um vasto conjunto de Consultores eventuais,
                                assegura-nos a prestação de um serviço de elevada qualidade e uma correcta catalogação
                                das peças em leilão.
      </Typography>
      <Typography align="left" sx={{ mb: 1 }}>
      O facto de muitos dos lotes começarem a 1 € permite ampliar e enriquecer a nossa oferta de
                                peças, dispondo de uma base mais alargada e numerosa de Clientes e possibilitar o pleno
                                e livre funcionamento do mercado através das licitações dos lotes em cada leilão.
      </Typography>
      <Typography align="left" sx={{ mb: 1 }}>
      Procuramos dar uma imagem de cada peça o mais aproximada possível da real através das
                                fotos publicadas no nosso site, complementadas com uma descrição detalhada das
                                características de cada peça. Se um potencial comprador quiser analisar os lotes em
                                leilão pode deslocar-se à nossa Galeria e observá-las com todo o detalhe e manuseá-las
                                na presença dos nossos especialistas.
      </Typography>
      <Typography align="left" sx={{ mb: 1 }}>
      A nossa galeria de exposição está situada na Rua Afonso Lopes Vieira, nº208, 4100-020,
      Porto (Foco)
      </Typography>
      
      <Typography variant="h6" sx={{ mt: 3, fontWeight: "bold" }}>
        POR FAVOR leia as Condições Gerais de Compra e Venda e Condições Negociais da CHANCE LEILÕES.
      </Typography>
    </Box>
  );
};

export default RegulamentoVendedor;
