import { Box, Typography } from "@mui/material";

const Privacidade = () => {
  return (
    <Box sx={{ maxWidth: "900px", margin: "auto", padding: { xs: "10px", sm: "20px" } }}>
      <Typography variant="h6" sx={{ mb: 1, fontWeight: "bold" }}>
      CHANCE LEILÕES - Privacidade
      </Typography>

      <Typography align="left" sx={{ mb: 0,fontWeight: "bold"  }}>
      Registo
      </Typography>

      <Typography align="left" sx={{ mb: 1 }}>
      Quando se regista no portal da Chance Leilões, guardamos os dados pessoais que nos fornece através do formulário de registo incluindo o seu endereço IP a data e hora do seu registo assim como a concordância com os Regulamentos da Chance Leilões.
      Os dados que guardamos são usados com os seguintes objectivos:
      </Typography>

      <Typography align="left" sx={{ mb: 1 }}>
      - o endereço IP conjuntamente com o seu e-mail para sua segurança
      </Typography>
      <Typography align="left" sx={{ mb: 1 }}>
      - o endereço e-mail para lhe comunicar que os seus lances já foram ultrapassados,  
        quais os lotes que venceu nos leilões em que se registou, e para notificação de novos leilões que irão decorrer.
      </Typography>

      <Typography align="left" sx={{ mb: 1 }}>
      - o cartão do cidadão/Bilhete de identidade, para dar cumprimento ao Decreto-Lei n.º 155/2015 de 10 de agosto Artigo 19º alínea e).
      </Typography>

      <Typography align="left" sx={{ mb: 1 }}>
      - o tipo de navegador,  as definições e o tipo de dispositivo, o sistema operativo para nos permitir melhorar a aplicação.
      </Typography>
      <Typography align="left" sx={{ mb: 1 }}>
      - são recolhidas informações sobre a data e a hora em que acedeu à plataforma da Chance Leilões unicamente para fins estatísticos.
      </Typography>
      <Typography align="left" sx={{ mb: 1 }}>
      - a password é do exclusivo conhecimento do utilizador. Se perder a password não existe forma de  saber  a password guardada. 
        Neste caso tem que proceder à "Recuperação de password" ou seja criar/guardar uma nova password.
      </Typography>
      <Typography align="left" sx={{ mb: 2 }}>
      - Os cookies usados pelo portal da Chance Leilões temo como função guardar o seu Id (cookie de sessão) da sessão em curso.
      </Typography>
      <Typography align="left" sx={{ mb: 1, fontWeight: "bold" }}>
      A Chance Leilões não vende nem negoceia os seus dados.
      </Typography>
      <Typography align="left" sx={{ mb: 0, fontWeight: "bold"  }}>
      Medidas de Segurança
      </Typography>
      <Typography align="left" sx={{ mb: 2 }}>
      A Chance Leilões está empenhada em proteger a segurança dos dados pessoais que o utilizador disponibiliza, 
        bem como em assegurar que os dados pessoais são recolhidos em condições de segurança.  
        No entanto, e apesar dos esforços para proteger os dados pessoais, o utilizador  deve estar consciente que há sempre algum risco de os seus dados poderem ser acedidos por terceiros, na medida em que circulam em redes abertas, como a Internet. 
        Assim, o utilizador deverá tomar regularmente precauções que possibilitem uma maior segurança enquanto utiliza a Internet.
      </Typography>
      <Typography align="left" sx={{ mb: 0, fontWeight: "bold" }}>
      Ficheiros de Registo Estatístico
      </Typography>
      <Typography align="left" sx={{ mb: 1 }}>
      São registados os endereços IP de todas as ligações ao Portal da Chance Leilões. Esta informação será utilizada para efetuar 
        análises estatísticas agregadas, não sendo estabelecida qualquer relação com os serviços e interações com os utilizadores.
         As análises efetuadas a partir da informação estatística agregada serão efetuadas para interpretar os padrões de utilização 
         do site e melhorar, de forma contínua, os níveis de serviço e a satisfação do utilizador.
      </Typography>
      <Typography align="left" sx={{ mb: 0, fontWeight: "bold"  }}>
      Conservação dos dados pessoais do utilizador
      </Typography>
      <Typography align="left" sx={{ mb: 1 }}>
      O período de tempo durante o qual os dados pessoais são armazenados e conservados varia de acordo com a finalidade para a qual
        a informação é tratada. Existem requisitos legais que obrigam a conservar os dados por um período de tempo mínimo. 
        Assim, e sempre que não exista uma exigência legal específica, os dados serão armazenados e conservados 
        apenas pelo período mínimo necessário para as finalidades que motivaram a sua recolha ou o seu posterior tratamento, 
        nos termos definidos na lei.
      </Typography>
      <Typography align="left" sx={{ mb: 1 }}>
      Procuramos dar uma imagem de cada peça o mais aproximada possível da real através das
                                fotos publicadas no nosso site, complementadas com uma descrição detalhada das
                                características de cada peça. Se um potencial comprador quiser analisar os lotes em
                                leilão pode deslocar-se à nossa Galeria e observá-las com todo o detalhe e manuseá-las
                                na presença dos nossos especialistas.
      </Typography>
      <Typography align="left" sx={{ mb: 0, fontWeight: "bold" }}>
      Cancelamento do registo
      </Typography>
      <Typography align="left" sx={{ mb: 1}}>
      Poderá a qualquer momento solicitar o cancelamento do seu Registo, bastando para isso enviar-nos um e-mail para  
        clientes@chanceleiloes.com ou geral@chanceleiloes.com e num prazo de 72 horas responderemos referindo as ações tomadas  
        sobre o seu pedido.
      </Typography>
      
      <Typography variant="h6" sx={{ mt: 3, fontWeight: "bold" }}>
        POR FAVOR leia as Condições Gerais de Compra e Venda e Condições Negociais da CHANCE LEILÕES.
      </Typography>
    </Box>
  );
};

export default Privacidade;
