import { Box, Typography } from "@mui/material";

const RegulamentoComprador = () => {
  return (
    <Box sx={{ maxWidth: "900px", margin: "auto", padding: { xs: "10px", sm: "20px" } }}>
      <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
        CHANCE LEILÕES - MUITOS LOTES TEM UM PREÇO INICIAL DE 1€
      </Typography>

      <Typography align="left" sx={{ mb: 1 }}>
        Sem qualquer tipo de reserva (preço mínimo) que condicione a sua aquisição no decurso do leilão. 
        Excecionalmente, a CHANCE LEILÕES em lotes com características especiais, reserva-se o direito de introduzir um preço de reserva mínimo estipulado em acordo com o vendedor.
      </Typography>

      <Typography align="left" sx={{ mb: 1 }}>
        Os leilões que promovemos podem ser acompanhados, a todo o tempo, através do nosso website{" "}
        <b>chanceleiloes.com</b> e as licitações podem decorrer diretamente no nosso site, telefónica 
        ou presencialmente na nossa sede à Rua Afonso Lopes Vieira 208, 4100-020 Porto.
      </Typography>

      <Typography align="left" sx={{ mb: 1 }}>
        Para licitar só precisará de se registar, identificar-se (Cartão do Cidadão ou Bilhete de Identidade), 
        indicar o número de Telefone, endereço de e-mail e ter mais de 18 anos. O processo de registo é simples e rápido. 
      </Typography>

      <Typography align="left" sx={{ mb: 1 }}>
        Caso queira apenas acompanhar os leilões "online", sem licitar, não necessita de se registar.
      </Typography>

      <Typography align="left" sx={{ mb: 1 }}>
        Todos os lotes presentes em cada leilão são agrupados no mesmo catálogo e ilustrados com várias fotografias e descrições detalhadas.
      </Typography>
      <Typography align="left" sx={{ mb: 1 }}>
        Todos os lotes colocados em venda nos Leilões que organizamos, estão em exposição nas
                                nossas instalações e disponíveis para serem manuseados, apreciados e examinados. Os
                                lotes encontram-se em exposição durante todo o período do leilão e nos horários
                                indicados.
      </Typography>
      <Typography align="left" sx={{ mb: 1 }}>
        Os Leilões com sessão final presencial que promoveremos serão especial e devidamente
                                assinalados no nosso site, sendo que o processo de licitação presencial decorrerá em
                                simultâneo com as licitações online e com as licitações telefónicas
      </Typography>
      <Typography align="left" sx={{ mb: 1 }}>
        Muitos dos bens cuja venda promovemos nos Nossos Leilões tem um preço inicial de 1€,
                                sem qualquer tipo de reserva (preço mínimo) que condicione a sua arrematação.
                                Excepcionalmente, a CHANCE LEILÕES reserva a possibilidade de, em peças com
                                características muito especiais e de grande valor unitário, introduzir por determinação
                                do vendedor, um preço mínimo de reserva.
      </Typography>
      <Typography align="left" sx={{ mb: 1 }}>
        1 - O comprador após o respectivo registo, poderá licitar livremente e em sintonia com
            as Condições Gerais de Compra e Venda que deverá analisar previamente.
      </Typography>
      <Typography align="left" sx={{ mb: 1 }}>
        2 - Sobre o valor da arrematação final incide a nossa comissão no valor de 18% (comissão mínima de 2€) acrescido da taxa de IVA em vigor.
      </Typography>
      <Typography align="left" sx={{ mb: 1 }}>
        3 - Todas as informações inerentes ao processo de registo são estritamente confidenciais.
      </Typography>
      <Typography align="left" sx={{ mb: 1 }}>
        4 - As licitações serão realizadas online, chanceleiloes.com, telefónica (tel 223 294 275) ou presencialmente na nossa sede.
      </Typography>
      <Typography align="left" sx={{ mb: 1 }}>
        5 - Todas as licitações são contratos de compra e venda celebrados e são considerados
            definitivos e vinculativos.
      </Typography>
      <Typography align="left" sx={{ mb: 1 }}>
        6 - Caso não tenha a possibilidade de acompanhar o leilão, poderá fazer uma oferta
                                máxima que implicará a licitação automática do sistema, até ao montante máximo que
                                definiu de forma totalmente segura. A opção de oferta máxima permitir-lhe-á cobrir os
                                lances de outros licitantes, caso existam, sem necessidade de acompanhar o encerramento
                                do leilão.
      </Typography>
      <Typography align="left" sx={{ mb: 1 }}>
        A sua oferta máxima cobrirá progressivamente as ofertas de outros licitantes, desde que
                                as outras licitações não ultrapassem o valor da sua oferta máxima. Através deste
                                sistema, poderá comprar pelo valor da penúltima licitação mais um lance.
      </Typography>
      <Typography align="left" sx={{ mb: 1 }}>
        7 - No caso de lotes com metais preciosos o comprador pode em caso de dúvida sobre a
                                autenticidade das marcas recorrer, para efeitos de verificação, aos serviços das
                                Contrastarias sediadas no Porto e em Lisboa (www.incm.pt).
      </Typography>
      <Typography align="left" sx={{ mb: 1 }}>
        8 - Em caso de litígio e nos termos do DL 144/2015 o Consumidor pode recorrer às 
            diferentes Entidades de Resolução Alternativa de Litígios de Consumo - RAL.
      </Typography>

      <Typography variant="h6" sx={{ mt: 3, fontWeight: "bold" }}>
        Pagamento e Levantamento dos Lotes
      </Typography>

      <Typography align="left" sx={{ mb: 1 }}>
        A CHANCE LEILÕES facultará ao licitante vencedor a respectiva conta no prazo de 5 dias
                                após a realizaçao do leilão, dispondo o Cliente do prazo legal de oito dias úteis após a
                                recepção do aviso de pagamento para efetuar o pagamento e o levantamento da mesma. O
                                preço final a pagar será o valor da arrematação final, acrescido da nossa comissão no
                                valor de 18% (comissão mínima de 2€) acrescido de IVA à taxa legal em vigor.
      </Typography>

      <Typography align="left" sx={{ mb: 1 }}>
        O pagamento pode ser feito em numerário, por transferência bancária ou cheque (neste caso, o respectivo lote só será entregue após boa cobrança do mesmo).
      </Typography>

      <Typography align="left" sx={{ mb: 1 }}>
        Poderá, após o pagamento do lote e suportando o custo de 8€ (para objectos até 2 kg sem 
                                fragilidade que não exijam embalemento especial), requerer o  envio das peças via 
                                express mail* (Portugal Continental). Objetos especiais podem determinar a cobrança de
                                uma taxa de enfardamento e embalamento especial. Envio para a Madeira e Açores sujeito
                                a orçamento prévio.
                                Sendo os lotes adquiridos enviados por transportadora e, sem prejuízo do manuseamento e embalamento da Nossa responsabilidade, 
                                os riscos de quebra ou dano registados durante esse transporte são da exclusiva responsabilidade do 
                                Comprador (um seguro de transporte poderá ser contratado para prevenir eventuais prejuízos).
      </Typography>
      <Typography align="left" sx={{ mb: 1 }}>
          O levantamento dos lotes poderá igualmente ser efetuado no local de exposição dos lotes,
                                na galeria da CHANCE LEILÕES na Rua Afonso Lopes Vieira, 208, 4100-020, entre as 14.00 e
                                20.00 horas <b>SUJEITO A MARCAÇÃO PRÉVIA</b> (geral@chanceleiloes.com ou 223294275). 
      </Typography>

      <Typography align="left" sx={{ mb: 1 }}>
        <b>Dados para Pagamento:</b> IBAN: PT50.0033.0000.45464882029.05 | Banco: Millennium BCP
      </Typography>

      <Typography align="left" sx={{ mb: 1 }}>
        Caso opte pelo método de transferência bancária, envie, por favor, o comprovativo de pagamento para o e-mail{" "}
        <b>clientes@chanceleiloes.com</b>
      </Typography>
      <Typography align="left" sx={{ mb: 1 }}>
        Lotes liquidados ou retirados e não levantados num prazo de 4 meses serão , de acordo com a sua natureza, 
        colocados em Leilão ou doados a Instituições Solidariedade Social.
      </Typography>
      <Typography align="left" sx={{ mb: 1 }}>
        *Os custos de envio para Portugal Continental dos lotes adquridos via express mail são de 8€ 
        por encomenda (para objectos até 2 kg sem fragilidade que não exijam embalemento especial), 
        para pesos superiores informaremos dos custos efectivos.
      </Typography>

      <Typography variant="h6" sx={{ mt: 3, fontWeight: "bold" }}>
        POR FAVOR leia as Condições Gerais de Compra e Venda e Condições Negociais da CHANCE LEILÕES.
      </Typography>
    </Box>
  );
};

export default RegulamentoComprador;
