import React from "react";
import { Box } from "@mui/material";
import Slideshow from "./Slideshow";
import Banner from "./Banner";
import Footer from "./Footer";
const Home = () => {
  return (
    <Box sx={{ backgroundColor: "white" }}>
      <Slideshow/>
      <Banner/>
      <Footer/>
    </Box>
  );
};

export default Home;
