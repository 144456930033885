import React from "react";
import { Container, Typography, Box } from "@mui/material";

const Avaliacoes = () => {
  return (
    <Container sx={{ textAlign: "center", py: 4 }}>
      {/* Section Title */}
      <Typography variant="h5" component="h1" fontWeight="bold" gutterBottom>
        AVALIAÇÕES
      </Typography>

      {/* Description */}
      <Box sx={{ textAlign: "left", mb: 2 }}>
        <Typography sx={{ mb: 1 }}>
          Avaliações de Arte, Antiguidades, Jóias, Pratas, Relógios e outros coleccionáveis são competências críticas do nosso negócio. Oferecemos uma grande variedade de serviços que no âmbito das avaliações podem ir de uma mera projecção do valor de mercado a uma justificada, documentada e ilustrada avaliação da peça em causa suportada na colaboração dos melhores consultores e especialistas do nosso mercado.
        </Typography>

        <Typography  sx={{ mb: 1 }}>
          Prestamos os nossos serviços na avaliação de antiguidades e objectos de arte de elevado valor, de bens destinados a inventários, para partilhas sucessórias ou fins testamenteiros, seguros multi-riscos, divisão de bens e análise de colecções.
        </Typography>

        <Typography  sx={{ mb: 1 }}>
          As estimativas/projecções de venda são totalmente gratuitas para peças que sejam colocadas nos nossos leilões.
        </Typography>

        <Typography  sx={{ mb: 1 }}>
          Procuramos dar uma imagem de cada peça o mais aproximada possível da real através das fotos publicadas no nosso site, complementadas com uma descrição detalhada das características de cada peça.
        </Typography>

        <Typography sx={{ mb: 1 }}>
          Contacte-nos através do telefone nº <strong>223 294 275 / 916 421 196</strong> ou envie-nos um email para{" "}
          <a href="mailto:clientes@chanceleiloes.com">clientes@chanceleiloes.com</a>, nós promoveremos a avaliação criteriosa dos seus bens.
        </Typography>
      </Box>

      {/* Image Section */}
      <Box textAlign="center">
        <img src="images/avaliacoes.jpg" alt="Avaliações" style={{ width: "100%" }} />
    </Box>

    </Container>
  );
};

export default Avaliacoes;
