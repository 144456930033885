import React from "react";
import Grid from "@mui/material/Grid2";
import { Box } from "@mui/material";

const Footer = () => {
  return (
     <Box sx={{ backgroundColor: "white" }}>
     <Grid container sx={{ backgroundColor: "#91791c" }}>
        <Grid item xs={12}>
          <input
            type="text"
            value=""
            disabled
            readOnly
            style={{
              width: "100%",
              backgroundColor: "#91791c",
              color: "#91791c",
              border: "none",
              height: "20px",
              cursor: "default",
            }}
          />
        </Grid>
      </Grid>
      </Box>
  )}

export default Footer;
