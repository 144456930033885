import { Accordion, AccordionSummary, AccordionDetails, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";


const bidIncrements = [
    { de: "€ 1", ate: "€ 6", incremento: "€ 2" },
    { de: "€ 7", ate: "€ 9", incremento: "€ 3" },
    { de: "€ 10", ate: "€ 29", incremento: "€ 4" },
    { de: "€ 30", ate: "€ 59", incremento: "€ 5" },
    { de: "€ 60", ate: "€ 99", incremento: "€ 8" },
    { de: "€ 100", ate: "€ 199", incremento: "€ 10" },
    { de: "€ 200", ate: "€ 399", incremento: "€ 20" },
    { de: "€ 400", ate: "€ 999", incremento: "€ 50" },
    { de: "€ 1.000", ate: "€ 4.999", incremento: "€ 100" },
    { de: "> € 5.000", ate: "-", incremento: "€ 250" },
  ];
const Faqs = () => {
  return (
    <div style={{ maxWidth: "900px", margin: "auto", padding: "20px" }}>
      <Typography variant="h5" sx={{ fontWeight: "bold", textAlign: "center", marginBottom: "20px" }}>
        Perguntas Frequentes
      </Typography>

      {faqData.map((item, index) => (
        <Accordion key={index}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">{item.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {item.content}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

const faqData = [
  {
    question: "Como posso Licitar?",
    content: (
      <>
        <Typography>
          Primeiro, crie um registo no CHANCE LEILÕES e ative-o seguindo as instruções enviadas por e-mail. 
          Após o registo, acesse a página de leilões, selecione o leilão desejado, clique em "Registo no Leilão", aceite os Termos e Condições e faça sua licitação.
        </Typography>
        <Typography>Nota: Para acompanhar os leilões, não é necessário registo.</Typography>
      </>
    ),
  },
  {
    question: "Dificuldades no Registo",
    content: (
      <>
        <Typography>Para validar seu registo e licitar sem restrições, preencha todos os campos obrigatórios e tenha mais de 18 anos.</Typography>
        <Typography>
          Se tiver dificuldades, entre em contato pelo telefone <b>223 294 275</b> ou e-mail <b>geral@chanceleiloes.com</b>.
        </Typography>
      </>
    ),
  },
  {
    question: "Que valor posso Licitar?",
    content: (
      <>
        <Typography>Os lances começam em 1€ sem preço mínimo. Abaixo estão os incrementos de licitação:</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell><b>De</b></TableCell>
                <TableCell><b>A</b></TableCell>
                <TableCell><b>Incrementos</b></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bidIncrements.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.de}</TableCell>
                  <TableCell>{row.ate}</TableCell>
                  <TableCell>{row.incremento}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    ),
  },
  {
    question: "Como posso Comprar ?",
    content: (
      <>
        <Typography>1 - O comprador pode licitar livremente conforme os Termos e Condições.</Typography>
        <Typography>2 - Sobre o valor final, há uma comissão de 18% + IVA.</Typography>
        <Typography>3 - As licitações podem ser feitas online, por telefone ou presencialmente.</Typography>
      </>
    ),
  },
  {
    question: "Pagamento e Levantamento/Entrega de Lotes",
    content: (
      <>
        <Typography>
          O pagamento pode ser feito em numerário, transferência bancária ou cheque. O comprador tem 8 dias úteis para pagar e retirar o lote.
        </Typography>
        <Typography>
          <b>Dados para Pagamento:</b> IBAN: PT50.0033.0000.45464882029.05 - Banco Millennium BCP
        </Typography>
      </>
    ),
  },
  {
    question: "Quando termina o leilão?",
    content: (
      <>
        <Typography>Os leilões têm um tempo máximo definido no catálogo, com contagem regressiva.</Typography>
        <Typography>Nos últimos minutos, novos lances prorrogam o tempo do leilão em mais 3 minutos.</Typography>
      </>
    ),
  },
  {
    question: "Como posso vender ?",
    content: (
      <>
        <Typography>
          Se tem peças usadas para vender, entre em contato pelo telefone <b>223 294 275</b> ou envie um e-mail para <b>clientes@chanceleiloes.com</b>.
        </Typography>
      </>
    ),
  },
  {
    question: "Onde Estamos e como nos Contactar?",
    content: (
      <>
        <Typography>
          Estamos no Porto, Rua Afonso Lopes Vieira, 208, 4100-020 (Foco). Funcionamos de segunda a sexta das 14:00 às 20:00.
        </Typography>
        <Typography>Contato: <b>223 294 275</b> ou <b>geral@chanceleiloes.com</b></Typography>
      </>
    ),
  },
];



export default Faqs;
