import { Box, Typography, Link } from "@mui/material";

const ResolucaoLitigios = () => {
  return (
    <Box sx={{ textAlign: "center", padding: "20px" }}>
      {/* Título principal */}
      <Typography variant="h5" sx={{ fontWeight: "bold", marginBottom: "20px" }}>
        Informação prestada nos termos do DL 144/2015 (Entidades de Resolução Alternativa de Litígios de Consumo - RAL)
      </Typography>

      {/* Introdução */}
      <Typography variant="h6" sx={{ marginBottom: "20px" }}>
        Em caso de litígio, o consumidor pode recorrer a uma Entidade de Resolução Alternativa de Litígios de Consumo:
      </Typography>

      {/* Lista de links para entidades */}
      <Box sx={{ display: "flex", flexDirection: "column", gap: "15px", alignItems: "center" }}>
        <Link href="http://www.centroarbitragemlisboa.pt/" sx={{ fontSize: "1.2rem", textDecoration: "none", color: "#0073e6" }}>
          Centro de Arbitragem de Conflitos de Consumo de Lisboa
        </Link>
        <Link href="http://www.triave.pt/" sx={{ fontSize: "1.2rem", textDecoration: "none", color: "#0073e6" }}>
          Centro de Arbitragem de Conflitos de Consumo do Vale do Ave/Tribunal Arbitral
        </Link>
        <Link href="http://www.ciab.pt/pt/" sx={{ fontSize: "1.2rem", textDecoration: "none", color: "#0073e6" }}>
          CIAB – Centro de Informação, Mediação e Arbitragem de Consumo (Tribunal Arbitral de Consumo)
        </Link>
        <Link href="http://www.arbitragemdeconsumo.org/" sx={{ fontSize: "1.2rem", textDecoration: "none", color: "#0073e6" }}>
          CNIACC – Centro Nacional de Informação e Arbitragem de Conflitos de Consumo
        </Link>
        <Link href="http://www.centrodearbitragemdecoimbra.com" sx={{ fontSize: "1.2rem", textDecoration: "none", color: "#0073e6" }}>
          Centro de Arbitragem de Conflitos de Consumo do Distrito de Coimbra
        </Link>
        <Link href="http://www.consumoalgarve.pt" sx={{ fontSize: "1.2rem", textDecoration: "none", color: "#0073e6" }}>
          Centro de Informação, Mediação e Arbitragem de Conflitos de Consumo do Algarve
        </Link>
        <Link href="http://www.cicap.pt" sx={{ fontSize: "1.2rem", textDecoration: "none", color: "#0073e6" }}>
          Centro de Informação de Consumo e Arbitragem do Porto
        </Link>
        <Link href="http://www.consumidor.pt" sx={{ fontSize: "1.2rem", textDecoration: "none", color: "#0073e6" }}>
          Mais informações em Portal do Consumidor
        </Link>
      </Box>
    </Box>
  );
};

export default ResolucaoLitigios;
