import React from "react";
import { Box, Typography, Container } from "@mui/material";

const QuemSomos = () => {
  return (
    <Container  sx={{ py: 4 }}>
      {/* Texto */}
      <Box textAlign="center" sx={{ mb: 4 }}>
        <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
          A CHANCE LEILÕES
        </Typography>
        <Typography variant="body1" align="left"  sx={{ mb: 1 }}>
          Somos um conjunto de Colaboradores com diferentes experiências de vida mas com ligações à arte, à história, à
          arquitectura, às antiguidades e ao coleccionismo.
        </Typography>
        <Typography variant="body1"align="left"  sx={{ mb: 1 }}>
          Procuramos no dia a dia enriquecer os nossos conhecimentos, estudar as peças e os lotes postos à nossa
          disposição, recorrer aos melhores especialistas, identificar a história de cada peça, compará-las e
          valorizá-las projetando o seu aproximado valor de mercado.
        </Typography>
        <Typography variant="body1" align="left"  sx={{ mb: 1 }}>
          O facto de todos os lotes começarem a 1 €, permite ampliar e enriquecer a nossa oferta de peças, dispondo de
          uma base mais alargada e numerosa de Clientes e possibilitar o pleno e livre funcionamento do mercado através
          das licitações dos lotes em cada leilão.
        </Typography>
        <Typography variant="body1" align="left"  sx={{ mb: 1 }}>
          Procuramos dar uma imagem de cada peça o mais aproximada possível da real através das fotos publicadas no
          nosso site, complementadas com uma descrição detalhada das suas características. Se um potencial comprador
          quiser analisar os lotes em leilão pode tranquilamente deslocar-se à nossa Galeria e observá-los com todo o
          detalhe e manuseá-los na presença dos nossos especialistas.
        </Typography>
        <Typography variant="body1" align="left"  sx={{ mb: 1 }}>
          A nossa galeria de exposições está situada na Rua Afonso Lopes Vieira, nº208, 4100-020, Porto (Foco).
        </Typography>
        <Typography variant="body1" align="left"  sx={{ mb: 1 }}>
          Responsável Técnico: <strong>Nuno Manuel Viana</strong>, contacto: <strong>223 294 275</strong>.
        </Typography>
        <Typography variant="body1" align="left"  sx={{ mb: 1 }}>
          Entre em contacto connosco através do telefone <strong>223 294 275</strong> ou enviando um email para{" "}
          <a href="mailto:geral@chanceleiloes.com">geral@chanceleiloes.com</a>.
        </Typography>
      </Box>

       <Box textAlign="center">
              <img src="images/quemsomos2.jpg" alt="Quem Somos" style={{ width: "100%" }} />
          </Box>
    </Container>
  );
};

export default QuemSomos;
